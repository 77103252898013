// pusherService.ts
import Pusher, { Channel } from 'pusher-js';
import NetworkManager from '../NetworkManager';

class PusherService {
  private static instance: PusherService | null = null;
  private pusher: Pusher;

  private constructor(restaurantUUID: string, tableUUID: string) {
    this.pusher = new Pusher(window._env_.PUSHER_APPKEY, {
      authorizer: (channel: Channel) =>
        NetworkManager.pusherAuthorizer(channel, restaurantUUID, tableUUID),
      cluster: window._env_.PUSHER_CLUSTER,
    });
  }

  public static getInstance(restaurantUUID: string, tableUUID: string): PusherService {
    if (!PusherService.instance) {
      PusherService.instance = new PusherService(restaurantUUID, tableUUID);
    }
    return PusherService.instance;
  }

  public getPusher(): Pusher {
    return this.pusher;
  }

  public static resetInstance(): void {
    PusherService.instance = null;  
  }
}

 
export default PusherService;
